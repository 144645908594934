export {
  menuAnimation,
  backAnimation,
  frontAnimation,
  headerAnimation,
  listAnimation,
  itemAnimation,
  ctaAnimation,
}

const menuAnimation = {
  open: {
    display: "block",
  },
  closed: {
    /*transition: {
      when: "afterChildren",
    },*/
    transitionEnd: {
      display: "none",
    },
  },
  hardClose: {
    transition: {
      display: "none",
      duration: 0,
    },
  },
}

const backAnimation = {
  open: {
    opacity: 1,
    transition: {
      type: "easeOut",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      type: "easeOut",
    },
  },
}

const frontAnimation = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      type: "easeOut",
    },
  },
  closed: {
    y: 0,
    opacity: 1,
    transition: {
      type: "easeOut",
    },
  },
}

const headerAnimation = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 1,
  },
}

const listAnimation = {
  open: {
    opacity: 1,
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.2,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
}

const itemAnimation = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: -60 },
}

const ctaAnimation = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.36,
    },
  },
  closed: {
    opacity: 0,
    y: -60,
  },
}
