import React from "react"
import styles from "./Close.module.scss"

const MenuClose = () => {
  return (
    <svg
      className={styles.icon}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 2L2 20" stroke="white" strokeWidth="3" />
      <path d="M2 2L20 20" stroke="white" strokeWidth="3" />
    </svg>
  )
}

export default MenuClose
