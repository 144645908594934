import React from "react"
import styles from "./BurgerBtn.module.scss"
import Burger from "../Burger"
import cn from "classnames"

const BurgerBtn = ({ onClick, className }) => {
  return (
    <button
      className={cn(styles.btn, className)}
      onClick={onClick}
      aria-label={"Menü"}
    >
      <Burger />
    </button>
  )
}

export default BurgerBtn
