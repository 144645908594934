import styles from "./Primary.module.scss"
import Link from "next/link"
import { motion } from "framer-motion"
import React from "react"
import { useRouter } from "next/router"

const Primary = ({ navTree, isOpen, toggleMenuFn = () => {} }) => {
  const router = useRouter()

  return (
    <nav className={styles.nav}>
      <motion.ul
        variants={listAnimation}
        animate={isOpen ? "open" : "closed"}
        initial="closed"
      >
        {navTree.map((navItem) => (
          <motion.li key={navItem.id} variants={itemAnimation}>
            <Link href={navItem.url}>
              <a
                className={
                  router.asPath?.startsWith(navItem.url) ? styles.active : ""
                }
                onClick={() => {
                  toggleMenuFn()
                }}
              >
                {navItem.title}
              </a>
            </Link>
          </motion.li>
        ))}
      </motion.ul>
    </nav>
  )
}

const listAnimation = {
  open: {
    opacity: 1,
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.2,
    },
  },
  closed: {
    opacity: 1,
    transition: {
      // when: "afterChildren",
      staggerChildren: 0.02,
    },
  },
}

const itemAnimation = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: -30 },
}

export default Primary
