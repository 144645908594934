import styles from "./Navigation.module.scss"
import Link from "next/link"
import BurgerBtn from "../BurgerBtn"

const Navigation = ({ navs, toggleMenuFn = () => {} }) => {
  const { mainNav = { items: [] } } = navs
  return (
    <nav id="main-navigation" className={styles.navigation}>
      {mainNav.items.map((navItem) => (
        <Link key={navItem.id} href={navItem.url}>
          <a className={navItem.border ? styles.highlight : undefined}>
            {navItem.title}
          </a>
        </Link>
      ))}
      {/*<BurgerBtn className={styles.burger} onClick={toggleMenuFn} />*/}
    </nav>
  )
}

export default Navigation
