import Header from "../Header"
import Footer from "../Footer"
import LetterDefinitions from "../ui/LetterImage/Definitions"
import { NextSeo } from "next-seo"
import styles from "./Layout.module.scss"
import { useRouter } from "next/router"

const isProdEnv = process.env.NODE_ENV === "production"
const debugGrid = !isProdEnv && process.env.NEXT_PUBLIC_DEBUG_GRID === "1"

function Layout({ navs = {}, company = {}, children, content = {}, seo = {} }) {
  const { asPath } = useRouter()

  const origin = process.env.NEXT_PUBLIC_ENV_HOST
    ? process.env.NEXT_PUBLIC_ENV_HOST
    : "http://localhost:3000"

  const _pathSliceLength = Math.min.apply(Math, [
    asPath.includes("?") ? asPath.indexOf("?") : asPath.length,
    asPath.includes("#") ? asPath.indexOf("#") : asPath.length,
  ])

  const canonical = `${origin}${asPath.substring(0, _pathSliceLength)}`

  const {
    id: pageId,
    title: pageTitle,
    seo_title: seoTitle,
    seo_description: seoDescription,
    seo_image: seoImage,
    seo_robots_no_index: seoNoIndex,
    seo_robots_no_follow: seoNoFollow,
  } = content

  const ogData = {
    url: canonical,
    title: seoTitle || pageTitle || company.name || null,
    description: seoDescription || null,
    images: [],
  }

  if (seoImage?.permalink) {
    ogData.images.push({
      url: seoImage.permalink,
      width: seoImage.width,
      height: seoImage.height,
      alt: seoImage.alt,
    })
  }

  // seo properties from outside will overwrite base props
  const seoProps = {
    canonical,
    title: seoTitle || pageTitle || company.name || null,
    description: seoDescription || null,
    noindex: !isProdEnv || seoNoIndex,
    nofollow: !isProdEnv || seoNoFollow,
    openGraph: ogData,
    ...seo,
  }

  const { footerNav, footerLinks } = navs
  return (
    <>
      <NextSeo {...seoProps} />
      {debugGrid && (
        <div className={styles.grid}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      <Header className="header" navs={navs} />
      <main className="main">{children}</main>
      <Footer
        pageId={pageId}
        footerNav={footerNav}
        footerLinks={footerLinks}
        company={company}
      />
      <LetterDefinitions className={styles.letterDefinitions} />
    </>
  )
}

export default Layout
