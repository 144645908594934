const LinkedinSvg = ({
  fill = "currentColor",
  preserveAspectRatio = "xMidYMid",
  width = 17,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={preserveAspectRatio}
    >
      <path
        d="M11.0664 5.3335C12.1273 5.3335 13.1447 5.75492 13.8948 6.50507C14.645 7.25521 15.0664 8.27263 15.0664 9.3335V14.0002H12.3997V9.3335C12.3997 8.97987 12.2593 8.64074 12.0092 8.39069C11.7592 8.14064 11.42 8.00016 11.0664 8.00016C10.7128 8.00016 10.3736 8.14064 10.1236 8.39069C9.87355 8.64074 9.73307 8.97987 9.73307 9.3335V14.0002H7.06641V9.3335C7.06641 8.27263 7.48783 7.25521 8.23798 6.50507C8.98813 5.75492 10.0055 5.3335 11.0664 5.3335Z"
        fill={fill}
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.39714 6H1.73047V14H4.39714V6Z"
        fill={fill}
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.0638 4.00016C3.80018 4.00016 4.39714 3.40321 4.39714 2.66683C4.39714 1.93045 3.80018 1.3335 3.0638 1.3335C2.32742 1.3335 1.73047 1.93045 1.73047 2.66683C1.73047 3.40321 2.32742 4.00016 3.0638 4.00016Z"
        fill={fill}
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkedinSvg
