import React from "react"

const Burger = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
    >
      <path d="M0.992595 0C0.285756 0 0 0.328298 0 0.971528V3.02847C0 3.67112 0.285756 4 0.992595 4H3.01263C3.71947 4 4 3.67112 4 3.02847V0.971528C4 0.328298 3.71424 0 3.00741 0H0.992595Z" />
      <path d="M0.992595 8C0.285756 8 0 8.3283 0 8.97153V11.0285C0 11.6711 0.285756 12 0.992595 12H3.01263C3.71947 12 4 11.6711 4 11.0285V8.97153C4 8.3283 3.71424 8 3.00741 8H0.992595Z" />
      <path d="M0.992595 16C0.285756 16 0 16.3283 0 16.9715V19.0285C0 19.6711 0.285756 20 0.992595 20H3.01263C3.71947 20 4 19.6711 4 19.0285V16.9715C4 16.3283 3.71424 16 3.00741 16H0.992595Z" />
      <path d="M8.99259 0C8.28576 0 8 0.328298 8 0.971528V3.02847C8 3.67112 8.28576 4 8.99259 4H11.0126C11.7195 4 12 3.67112 12 3.02847V0.971528C12 0.328298 11.7142 0 11.0074 0H8.99259Z" />
      <path d="M8.99259 8C8.28576 8 8 8.3283 8 8.97153V11.0285C8 11.6711 8.28576 12 8.99259 12H11.0126C11.7195 12 12 11.6711 12 11.0285V8.97153C12 8.3283 11.7142 8 11.0074 8H8.99259Z" />
      <path d="M8.99259 16C8.28576 16 8 16.3283 8 16.9715V19.0285C8 19.6711 8.28576 20 8.99259 20H11.0126C11.7195 20 12 19.6711 12 19.0285V16.9715C12 16.3283 11.7142 16 11.0074 16H8.99259Z" />
      <path d="M16.9926 0C16.2858 0 16 0.328298 16 0.971528V3.02847C16 3.67112 16.2858 4 16.9926 4H19.0126C19.7195 4 20 3.67112 20 3.02847V0.971528C20 0.328298 19.7142 0 19.0074 0H16.9926Z" />
      <path d="M16.9926 8C16.2858 8 16 8.3283 16 8.97153V11.0285C16 11.6711 16.2858 12 16.9926 12H19.0126C19.7195 12 20 11.6711 20 11.0285V8.97153C20 8.3283 19.7142 8 19.0074 8H16.9926Z" />
      <path d="M16.9926 16C16.2858 16 16 16.3283 16 16.9715V19.0285C16 19.6711 16.2858 20 16.9926 20H19.0126C19.7195 20 20 19.6711 20 19.0285V16.9715C20 16.3283 19.7142 16 19.0074 16H16.9926Z" />
    </svg>
  )
}

export default Burger
