import LinkButton from "../../../ui/LinkButton"
import parse from "html-react-parser"
import styles from "./Cta.module.scss"

const Cta = ({ description, linkText, url, onClick = () => {} }) => {
  return (
    <>
      <div className={styles.cta}>
        <div className={styles.text}>{description && parse(description)}</div>
        <LinkButton
          className="button--inverted"
          data={{ link_url: url, link_text: linkText }}
          onClick={onClick}
        />
      </div>
    </>
  )
}

export default Cta
