export const letters = {
  v: {
    path: "M959.13 630.41C965.608 631.056 972.148 630.175 978.223 627.837C984.298 625.499 989.738 621.771 994.104 616.952L1165.35 433.082C1170.62 428.44 1174.84 422.724 1177.71 416.32C1180.58 409.916 1182.04 402.972 1182 395.956V0H898.855V338.873H833.742V0H0.0978735V396.141C-0.351711 403.045 0.751507 409.962 3.32657 416.386C5.90163 422.81 9.88342 428.578 14.9807 433.268L194.225 616.673C198.592 621.492 204.031 625.221 210.106 627.559C216.181 629.896 222.721 630.778 229.199 630.132L959.13 630.41Z",
    width: 1182,
    height: 631,
  },
  o: {
    path: "M67.0264 0C19.2787 0 0 20.4382 0 60.4144L0.00013966 810.586C0.00013966 850.586 19.2788 871 67.0265 871H592.475C640.197 871 659 850.586 659 810.586L659 60.4144C659 20.4382 639.721 0 592 0H67.0264Z",
    width: 659,
    height: 870,
  },
  l: {
    path: "M0.0788574 441.116L0.0766602 1078H719.998L720 0.0807889H456.317C439.959 -0.520179 423.651 2.23673 408.391 8.18289C393.131 14.1291 379.24 23.1393 367.569 34.6622L43.0113 363.428C30.1068 375.533 18.5581 389.017 8.5697 403.639C2.40485 415.126 -0.530579 428.082 0.0788574 441.116Z",
    width: 720,
    height: 1078,
  },
}

const LetterDefinitions = ({ ...props }) => {
  return (
    <svg
      {...props}
      x={0}
      y={0}
      viewBox="0 0 1 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath
          id="letter-path-v"
          clipPathUnits="objectBoundingBox"
          transform={`scale(${1 / letters.v.width}, ${1 / letters.v.height})`}
        >
          <path d={letters.v.path} />
        </clipPath>
        <clipPath
          id="letter-path-o"
          clipPathUnits="objectBoundingBox"
          transform={`scale(${1 / letters.o.width}, ${1 / letters.o.height})`}
        >
          <path d={letters.o.path} />
        </clipPath>
        <clipPath
          id="letter-path-l"
          clipPathUnits="objectBoundingBox"
          transform={`scale(${1 / letters.l.width}, ${1 / letters.l.height})`}
        >
          <path d={letters.l.path} />
        </clipPath>
        <clipPath
          id="letter-path-l-horizontal"
          clipPathUnits="objectBoundingBox"
        >
          <path d="M0.613247 0.000109467L1 0.00010945L1 1L0.000112321 1L0.000112305 0.633774C-0.000723172 0.611054 0.00310922 0.588404 0.0113757 0.56721C0.0196421 0.546015 0.0321686 0.526723 0.048188 0.510512L0.505243 0.0597377C0.522072 0.0418148 0.540817 0.0257751 0.561146 0.0119023C0.577116 0.00334004 0.595127 -0.000736978 0.613247 0.000109467Z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LetterDefinitions
