const XingSvg = ({
  fill = "currentColor",
  preserveAspectRatio = "xMidYMid",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={preserveAspectRatio}
    >
      <path
        fill={fill}
        d="M12.318 0c-.345 0-.494.217-.618.439L6.565 9.545l3.279 6.015c.114.204.291.44.644.44h2.305c.139 0 .248-.052.307-.148.061-.099.059-.229-.005-.357L9.842 9.552c-.003-.005-.003-.011 0-.015l5.11-9.034c.064-.127.065-.257.004-.356C14.897.052 14.788 0 14.649 0h-2.331z"
      />
      <path
        fill={fill}
        d="M2.617 2.92c-.139 0-.248.053-.307.148-.061.099-.059.228.005.357l1.568 2.693a.015.015 0 0 1 0 .016l-2.458 4.33c-.064.128-.065.258-.004.356.059.095.168.147.306.147h2.314c.345 0 .5-.227.618-.44l2.5-4.402L5.566 3.36c-.115-.205-.291-.44-.645-.44H2.616z"
      />
    </svg>
  )
}

export default XingSvg
