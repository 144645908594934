import styles from "./Secondary.module.scss"
import Link from "next/link"
import React from "react"

const Secondary = ({
  title,
  navTree,
  target = "_self",
  toggleMenuFn = () => {},
}) => {
  return (
    <nav className={styles.nav}>
      <div className={styles.title}>{title}</div>
      <ul>
        {navTree.map((navItem) => (
          <li key={navItem.id}>
            <Link href={navItem.url}>
              <a target={target} onClick={() => toggleMenuFn()}>
                {navItem.title}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Secondary
